'use strict';

/* global location */

var base = require('../product/base');
var focusHelper = require('base/components/focus');
var birthCertificate = require('../product/birthCertificate');
// const Swiper = require('swiper/swiper-bundle.min.js');
var dataLayer = window.dataLayer || [];

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

function showNotification(data, duration) {
    const $notification = $('.notification');
    const $notificationMessage = $('.showMessage');
    const $alert = $('.alert');
    $notificationMessage.text(data.errorMessage);
    $alert.addClass('alert-danger');
    $notification.removeClass('d-none').addClass('show');
    setTimeout(function () {
        $notification.addClass('d-none').removeClass('show');
        $alert.removeClass('alert-danger');
    }, duration);
}

function updateShiptAmount(shiptAmount, status, $thisData) {
    var url = $thisData.data('url');
    $.spinner().start();
    $('body').trigger('cart:beforeUpdate');
    var amount = { amount: shiptAmount };
    $.ajax({
        url: url,
        type: 'post',
        context: this,
        dataType: 'json',
        data: amount,
        success: function (data) {
            if (data.success) {
                if (data.amount) {
                    $(".shipping-tip").text("+ " + data.amount + " tip");
                } else {
                    $(".shipping-tip").text("+ tip");
                }
                if (status == 'R') {
                    window.location.reload();
                }
                $(".carttipamount").attr('data-tipamount', data.amount);
                $(".setTipAmount").val(data.newAmount);
            } else {
                $('.invalid-amount').text(data.errorMessage).removeClass('d-none');
                setTimeout(function () {
                    $('.invalid-amount').text('').addClass('d-none');
                }, 2000);
                $(".shipping-tip").text("+ tip");
                $(".shiptCustomAmount").val('');
            }
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

function updatePromoCodeDetail(eventClass) {
    $.spinner().start();

    const $couponError = $('.coupon-error');
    const $promoCodeFormControl = $('.promo-code-form .form-control');
    const $couponMissingError = $('.coupon-missing-error');
    const $couponErrorMsg = $('.coupon-error-message');
    const $couponCodeField = $('.promo-code-form .coupon-code-field');
    const $couponCodePromo = $('.coupons-and-promos');
    const $couponCodeFieldValue = $couponCodeField.val();
    $couponMissingError.hide();
    $couponErrorMsg.empty();
    if (!$couponCodeFieldValue && eventClass == 'promo-code-btn') {
        $promoCodeFormControl.addClass('is-invalid');
        $promoCodeFormControl.attr('aria-describedby', 'missingCouponCode');
        $couponMissingError.show();
        $couponError.addClass('pb-3');
        $.spinner().stop();
        return false;
    }
    let $form = $('.promo-code-form');
    const $url = $form.attr('data-action');
    $promoCodeFormControl.removeClass('is-invalid');
    $couponErrorMsg.empty();
    $('body').trigger('promotion:beforeUpdate');

    var obj = {};
    $(".promocodeforms > input").each(function () {
        obj[$(this).attr('name')] = $(this).val();
    });

    $.ajax({
        url: $url,
        type: 'GET',
        dataType: 'json',
        data: obj,
        success: function (data) {
            var error = data.basketModel ? data.basketModel.valid.error : data.error;
            if (error) {
                if (eventClass == 'promo-code-btn') {
                    $promoCodeFormControl.addClass('is-invalid');
                    $promoCodeFormControl.attr('aria-describedby', 'invalidCouponCode');
                    $couponErrorMsg.empty().append(data.errorMessage).show();
                    $(window).scrollTop($('.promo-code-form').offset().top - 150);
                    $couponError.addClass('pb-3');
                } else {
                    $('.alert-danger span').text(data.errorMessage).parent().removeClass('d-none');
                    $('.alert-danger p').text(data.errorMessage).parent().removeClass('d-none');
                    $(window).scrollTop($('.promo-code-form').offset().top - 150);
                    setTimeout(function () {
                        $('.alert-danger span').text('').parent().addClass('d-none');
                        $('.alert-danger p').text('').parent().addClass('d-none');
                    }, 2000);
                }

                $('body').trigger('promotion:error', data);
            } else {
                if (eventClass == 'promo-code-btn') {
                    $('.alert-success span').text(data.successMessage).parent().removeClass('d-none');
                    $('.alert-success p').text(data.successMessage).parent().removeClass('d-none');
                    $(window).scrollTop($('.promo-code-form').offset().top - 150);
                    setTimeout(function () {
                        $('.alert-success span').text('').parent().addClass('d-none');
                        $('.alert-success p').text('').parent().addClass('d-none');
                    }, 2000);
                    $couponCodePromo.empty().append(data.basketModel.totals.discountsHtml);
                } else {
                    $('.alert-success span').text(data.successMessage).parent().removeClass('d-none');
                    $('.alert-success p').text(data.successMessage).parent().removeClass('d-none');
                    $(window).scrollTop($('.promo-code-form').offset().top - 150);
                    setTimeout(function () {
                        $('.alert-success span').text('').parent().addClass('d-none');
                        $('.alert-success p').text('').parent().addClass('d-none');
                    }, 2000);
                    $couponCodePromo.empty().append(data.basketModel.totals.discountsHtml);
                }
                updateCartTotals(data.basketModel);
                updateApproachingDiscounts(data.basketModel.approachingDiscounts);
                validateBasket(data.basketModel);
                if (data.basketModel.totals.grandTotal.value === 0) {
                    $('.adyenForm-custom-info').addClass('d-none');
                    $('.grand-total-sum').data('total', data.basketModel.totals.grandTotal.value);
                } else {
                    $('.adyenForm-custom-info').removeClass('d-none');
                    $('.grand-total-sum').data('total', data.basketModel.totals.grandTotal.value);
                }
                $('body').trigger('promotion:success', data.basketModel);
                // window.location.reload();
            }
            $couponCodeField.val('');
            $.spinner().stop();
        },
        error: function (err) {
            $('body').trigger('promotion:error', err);
            $.spinner().stop();
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.errorMessage);
            }
        }
    });
    return false;
}


/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

function updateTotalAddonsPrice(furryFriedlineItemUUID, allItems) {
    const $lineItemContainer = $(`.cart-lineitem[data-uuid=${furryFriedlineItemUUID}]`);
    const furryFriendLineItem = allItems.find(function (item) {
        return item.UUID === furryFriedlineItemUUID;
    });
    const currency = $lineItemContainer.data('currency');

    // total lineItem price (main product plus addons)
    if (furryFriendLineItem.addOnPlusMainPrice.list.decimalPrice !== furryFriendLineItem.addOnPlusMainPrice.sales.decimalPrice && (furryFriendLineItem.addOnPlusMainPrice.list.decimalPrice > 0)) {
        $lineItemContainer.find('.strike-through.list .value').eq(0).text(currency + furryFriendLineItem.addOnPlusMainPrice.list.decimalPrice.toFixed(2));
    } else {
        $lineItemContainer.find('.strike-through.list .value').eq(0).text('');
    }
    if (furryFriendLineItem.addOnPlusMainPrice.sales.decimalPrice) {
        $lineItemContainer.find('.sales .value').eq(0).text(currency + furryFriendLineItem.addOnPlusMainPrice.sales.decimalPrice.toFixed(2));
    }

    // if all addons removed, remove accordion and return
    if (furryFriendLineItem.totalAddOns == 0) {
        $lineItemContainer.find('.builder-line-item-accordion').remove();
        return;
    }
    // total addons title and price
    $lineItemContainer.find('.total-addon-count').text(`(${furryFriendLineItem.totalAddOns})`);

    if (furryFriendLineItem.totalAddOnPrice.list.decimalPrice !== furryFriendLineItem.totalAddOnPrice.sales.decimalPrice) {
        $lineItemContainer.find('.total-pricing-list .strike-through .value').eq(0).text(currency + furryFriendLineItem.totalAddOnPrice.list.decimalPrice.toFixed(2));
    } else {
        $lineItemContainer.find('.total-pricing-list .strike-through .value').eq(0).text('');
    }
    if (furryFriendLineItem.totalAddOnPrice.sales.decimalPrice) {
        $lineItemContainer.find('.total-pricing-list .sales .value').eq(0).text(currency + furryFriendLineItem.totalAddOnPrice.sales.decimalPrice.toFixed(2));
    }
    // update each addon item
    const allDepartments = [];
    furryFriendLineItem.addOnLineItems.forEach(function (addOnItem) {
        let addOnDepartment = addOnItem[0];
        let addOnItemCount = addOnItem[1].length;
        allDepartments.push(addOnDepartment);
        $lineItemContainer.find(`#${addOnDepartment} .addon-count`).text(`(${addOnItemCount})`);
    });

    // remove child addon if empty
    $lineItemContainer.find('.rowAddOns').children().each(function (ind, ele) {
        let deptId = $(ele).attr('id');
        if (allDepartments.indexOf(deptId) < 0) {
            $(ele).remove();
        }
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.shipping-total-cost').empty().append(data.totals.totalShippingCost);
    if (data.totals.totalTax.value > 0 || (data.totals.grandTotal.value == 0 && data.totals.totalTax.value == 0)) {
        $('.tax-total').empty().append(data.totals.totalTax.formatted);
    } else {
        $('.tax-total').empty().append('TBD');
    }
    $('.grand-total').empty().append(data.totals.grandTotal.formatted);
    $('.sub-total').empty().append(data.totals.subTotal.formatted);
    $('.sub-total').empty().append(data.totals.subTotal.formatted);
    $('.updateLoyaltyPoint').empty().append(data.loyaltyPoint);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('d-none hide-order-discount');
        $('.order-discount-total').empty()
            .append(data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('d-none hide-order-discount');
    }

    if (data.totals.discounts) {
        for (let i = 0; i < data.totals.discounts.length; i++) {
            if (data.totals.discounts[i].type == 'coupon') {
                $('.order-discount').find('span.order-receipt-label1').addClass('d-block').removeClass('d-none');
            }
        }
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('d-none hide-shipping-discount');
        $('.shipping-discount-total').empty().append(data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('d-none hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('#editProductModal').on('shown.bs.modal', function () {
                base.images();
            });
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

function stickyCheckoutButton() {
    const $totalOrderSummary = $(".totals .order-summary");
    const $cartLeftSide = $(".cart-letfside");
    if ($cartLeftSide.length) {
        $cartLeftSide[0].getBoundingClientRect().bottom + 220 > $(window).height() ? $totalOrderSummary.addClass("sticky-button") : $totalOrderSummary.removeClass("sticky-button");
    }
}

$(window).on("scroll resize", function () {
    stickyCheckoutButton();
});

function giftMessageFormClear(form) {
    form.removeClass('is-invalid');
    form.next('.invalid-feedback').text('');
}

function updatePriceTotal(productId, lineItems, uuid) {
    let $productLineItem = $(`[data-uuid=${uuid}].product-line-card`);

    for (let i = 0; i < lineItems.length; i++) {
        let lineItem = lineItems[i];
        let currencySymbol = lineItem.price.sales.symbol; //eslint-disable-line

        if (lineItem.id === productId.toString()) {
            // update list price
            if (lineItem.priceTotal.nonAdjustedPriceValue) {
                $productLineItem.find('.strike-through.list > .value').text(lineItem.priceTotal.nonAdjustedPrice);
            } else if (lineItem.price.list && !lineItem.priceTotal.nonAdjustedPriceValue) {
                $productLineItem.find('.strike-through.list > .value').text(currencySymbol + (lineItem.price.list.value * lineItem.quantity).toFixed(2));
            }

            // update sale price
            if (lineItem.priceTotal.adjustedPrice) {
                $productLineItem.find('.sales > .value').text(lineItem.priceTotal.price);
            }
            break;
        }
    }
}

function updateCartAttribute(uuid, items) {
    const $itemCont = $(`.cart-lineitem[data-uuid=${uuid}]`);
    let lineItem = items.find(function (item) {
        return item.UUID === uuid;
    });
    if (lineItem.allVariationAttributes) {
        lineItem.allVariationAttributes.forEach(function (attr) {
            let $attr = $itemCont.find(`[data-attr=${attr.id}]`);
            attr.values.forEach(function (attrVal) {
                let $attrValue = $attr.find(`[data-attr-value=${attrVal.value}]`);
                $attrValue.attr('value', attrVal.url);
            });
        });
    }
}

module.exports = function () {
    $('.shiptUpdateDetail').on('click', '.tip-percent', function (ele) {
        ele.preventDefault();
        var shiptAmount = $(this).data('value');
        updateShiptAmount(shiptAmount, 'A', $(this));
    });

    $('.shiptUpdateDetail').on('click', '.tip-percent-removed', function (ele) {
        ele.preventDefault();
        var shiptAmount = $(this).data('value');
        updateShiptAmount(shiptAmount, 'R', $(this));
    });
    $('.shipt-add').on('click', '.shiptSubmit', function (ele) {
        ele.preventDefault();
        var shiptAmount = $(".shiptCustomAmount:input").val();
        updateShiptAmount(shiptAmount, 'A', $(this));
    });
    $('.donation').on('change', '.donation-select', function () {
        const $donationAmount = $('.custom-donation-amount');
        if ($(this).val() === 'custom-donation') {
            $donationAmount.removeClass('d-none');
            $('.donation-amount').attr('required', true);
        } else {
            $donationAmount.addClass('d-none');
            $('.donation-amount').attr('required', false);
        }
    });

    $('.donation-amount').on('keypress', function () {
        if ($(this).val().length >= 4) {
            return false;
        }
    });

    $('.options-inline').on('click', '.isgift', function () {
        let $isGift = $(this);
        $(".giftCHeck").val($(this).val());
        if ($isGift.is(':checked') && $(this).val() === 'true') {
            $("#DivToShowHide").removeClass('d-none');
            $('.giftMsgVarification').removeClass('is-invalid');
        } else {
            $("#DivToShowHide").addClass('d-none');
            $(".giftfrom").val('');
            $(".giftmessage").val('');
        }
    });

    $('.giftMsgVarification').on('change blur', function () {
        let messages = $(this).parents('.form-group').find('.invalid-feedback');
        var thisval = $(this).val();
        // check for unfilled
        if ($.trim(thisval).length == 0) {
            $(this).addClass('is-invalid');
            $(this).parents('.form-group').find('.invalid-feedback').text(messages.data('empty-msg'));
        }
        // replace Microsoft's weird sided quotes that may have been pasted in (this has been a problem)
        if (thisval.match(/[‘’]/g)) {
            thisval = thisval.replace(/[‘’]/g, '\'');
            $(this).val(thisval);
        }
        var giftregex = /^[a-zA-Z0-9\,\. \n\!\?\-\&\+\']*$/;
        var isValid = giftregex.test($.trim(thisval));
        // check for invalid characters and too many rows
        if (!isValid) {
            $(this).addClass('is-invalid');
            $(this).parents('.form-group').find('.invalid-feedback').text(messages.data('error-msg'));
        } else if (thisval && thisval.match(/\n/g) && thisval.match(/\n/g).length >= 3) {
            $(this).addClass('is-invalid');
            $(this).parents('.form-group').find('.invalid-feedback').text('Please do not use more than 2 line breaks.');
        } else {
            $(this).removeClass('is-invalid');
        }
    });

    const $maxLength = $('.single-shipping .giftmessage').attr('data-character-limit');
    $('.single-shipping textarea').keyup(function () {
        let textlen = $maxLength - $(this).val().length;
        $('.single-shipping .char-remain-count').text(textlen);
    });

    $('.donation').on('click', '.donation-edit', function (event) {
        event.preventDefault();
        $('.addCartDonation').removeClass('d-none');
        $('.item-edit-links').addClass('d-none');
        $('.current-donation').removeClass('d-none');
    });

    $('.list-coupon-confirmation-body').on('click', '.apply-coupon', function (ele) {
        ele.preventDefault();
        let couponCode = $(this).data('code');
        $(".coupon-code-field").val(couponCode);
        updatePromoCodeDetail('promo-code-btn');
        $('#promocode-modal').modal('hide');
    });

    $('.donation').on('click', '.donation-remove', function () {
        var url = $(this).data('action');
        window.location.href = url;
    });
    $('.updateMessage').click(function (event) {
        event.preventDefault();
        var messageVal = $(".giftmessage").val();
        if (messageVal) {
            if (messageVal.indexOf("|") > -1) {
                messageVal = messageVal.split('|');
                $(".giftmessage").val(messageVal[1].trim());
            } else {
                $(".giftmessage").val(messageVal);
            }
        }
    });
    $('form.donation-form').submit(function (event) {
        event.preventDefault();
        var $donationAmount = $('.donation-select').val();
        if ($donationAmount === 'custom-donation') {
            $donationAmount = $('.donation-amount').val();
        }

        if ($donationAmount) {
            var url = $(this).attr('action');
            var params = {
                amount: $donationAmount
            };
            url = appendToUrl(url, params);
            window.location.href = url;
        }
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                try {
                    if (data.isEnabled) {
                        dataLayer.push(JSON.parse(data.dataLayerObj));
                    }
                } catch (error) {
                    window.console.error('\ncart.js: error in {0}', error);
                }
                if (data.basket.items.length === 0) {
                    window.location.href = window.location.origin + window.location.pathname;
                    $('.cart').empty().append('<div class="row"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                        '</div> ' +
                        '</div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    if (data.checkMelcoSessionStatus) {
                        $('.melcoSession-error').addClass('d-none');
                    } else {
                        $('.melcoSession-error').removeClass('d-none');
                    }
                    $('html').removeClass('veiled');
                } else {
                    if (data.inventoryStatus) {
                        $('.invetory-error').addClass('d-none');
                    } else {
                        $('.invetory-error').removeClass('d-none');
                    }
                    if (data.checkMelcoSessionStatus) {
                        $('.melcoSession-error').addClass('d-none');
                    } else {
                        $('.melcoSession-error').removeClass('d-none');
                    }
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    if (data.skoosherzToBeDeleted && data.skoosherzToBeDeleted.length > 0) {
                        for (var k = 0; k < data.skoosherzToBeDeleted.length; k++) {
                            $('.uuid-' + data.skoosherzToBeDeleted[k]).remove();
                        }
                    }
                    if (data.furryFriendLineItemUUID) {
                        updateTotalAddonsPrice(data.furryFriendLineItemUUID, data.basket.items);
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    if (data.basket.numItems == 1 && data.newShippingMethod) {
                        $(".updateCartShipping").text(data.newShippingMethod);
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                    // if (data.qtyRemain) {
                    //     console.log('Test1');
                    //     $('.qtyRemain').val(data.qtyRemain);
                    //     if ($('.cart-top-product-slider').length) {
                    //         if ($('.qtyRemain').val() > 0) {
                    //             console.log('Test');
                    //             $(".cart-top-product-slider .add-to-cart-tile").attr('disabled', false);
                    //         }
                    //     }
                    // }
                }
                if (data.stage == 'payment') {
                    var checkoutUrl = $(".checkout-btn").attr('href') + "?stage=payment";
                    $(".checkout-btn").attr('href', checkoutUrl);
                }

                if (!data.isBonusAvaliable) {
                    $('.giftcard-bonus-available').addClass('d-none');
                } else {
                    $('.giftcard-bonus-available').removeClass('d-none');
                }

                $('body').trigger('cart:update', data);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updatePriceTotal(productID, data.items, uuid);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                updateCartAttribute(uuid, data.items);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').on('click', '.promo-code-btn', function (ele) {
        ele.preventDefault();
        updatePromoCodeDetail('promo-code-btn');
    });
    $('.promo-code-form').on('keydown', '.coupon-code-field', function (ele) {
        if (ele.which === 13) {
            ele.preventDefault();
            updatePromoCodeDetail('promo-code-btn');
        }
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');
        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('.customer-information-block').on('input', '.email-guest', function () {
        $('.submit-customer').prop('disabled', false);
    });

    function customerCheckRegistrationAccount() {
        var url = $(".emailRegistration").val();
        var email = $('.customer-information-block .email-guest').val();
        let createAccountUrl = $('.guestNoti').data('createAccountUrl');
        var urlParams = {
            registeremail: email,
            createAccountUrl: createAccountUrl
        };

        url = appendToUrl(url, urlParams);
        const $emailguest = document.querySelector('#email-guest');
        if (!$emailguest.validity.valid) {
            return 0;
        }
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            beforeSend: function () {
                $('.submit-customer').prop('disabled', true);
            },
            success: function (data) {
                $('.guestNoti').addClass('d-none');
                if (data.success === true) {
                    $('.guestNoti').removeClass('d-none');
                    $('.submit-customer').prop('disabled', false);
                } else if (data.success === false) {
                    $('.guestNoti').removeClass('d-none');
                    $('.submit-customer').prop('disabled', false);
                }
                $(".updateGuestNotification").empty().html(data.message);
                $('body').trigger('Account:checkRegistrationAccount', { userStatus: data });
            }
        });
    }

    let typingDelay;
    let typingInterval = 500;

    // Changed class this is looking for because commenting out the call makes Lint mad about an unused function.
    $('.customer-information-block').on('keyup', '.email-guest-doesntexist', function () {
        clearTimeout(typingDelay);
        if ($('.customer-information-block .email-guest').val()) {
            typingDelay = setTimeout(customerCheckRegistrationAccount, typingInterval);
        }
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
                location.reload();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('click', '.select-bonus', function (evt) {
        evt.preventDefault();
        if ($giftmodalPrompt.length) {
            $giftmodalPrompt.modal('hide');
        }
        const url = $(this).attr('href');
        $.ajax({
            url: url,
            method: 'get',
            success: function (data) {
                $('.bonus-prod-modal').html(data.renderedTemplate);
                $("#gift-bonus-modal").modal('show');
            }
        });
    });

    $('body').on('click', '.bonus-add-to-cart', function (evt) {
        evt.preventDefault();
        const $addToCartBonus = $(this);
        let url = $addToCartBonus.data('url');
        const pidsObject = {
            bonusProducts: []
        };
        const selectedBonusProducts = $('[data-selectdbonusprods]').data('selectdbonusprods');
        selectedBonusProducts.forEach(function (item) {
            pidsObject.bonusProducts.push({
                pid: item.pid,
                qty: item.submittedQty,
                options: item.option
            });
        });
        const pid = $addToCartBonus.data('pid');
        const duuid = $addToCartBonus.data('duuid');
        const quantity = parseInt($addToCartBonus.siblings('.quantity-list').find('.quantity-select').val());
        const pliuuid = $addToCartBonus.data('pluuid');

        pidsObject.bonusProducts.push({
            pid: pid,
            qty: quantity,
            options: null
        });
        const params = {
            pids: JSON.stringify(pidsObject),
            uuid: duuid,
            pliuuid: pliuuid
        };
        url = appendToUrl(url, params);
        $.spinner().start();

        $.ajax({
            url: url,
            method: 'POST',
            success: function (data) {
                $.spinner().stop();
                if (data.success) {
                    $('.addtocart-qantity').append(data.msgSuccess);
                }
                $('.minicart-quantity').html(data.totalQty);
                setTimeout(() => {
                    $("#gift-bonus-modal").modal('hide');
                    location.reload();
                }, 1500);
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
    const $giftmodalPrompt = $('#gift-modal-prompt');
    $(document).ready(function () {
        if ($giftmodalPrompt.length) {
            $giftmodalPrompt.modal('show');
        }

        $giftmodalPrompt.on('click', '.show-promo-details', function () {
            $('.promo-details').toggleClass('d-none');
        });

        if ($('.list-coupon-confirmation-body .drawer-tile').length) {
            $('.promo-title').removeClass('d-none');
        }
        stickyCheckoutButton();
    });

    $(document).on('submit', '.cart-page .hb-giftmsg-form', function (evt) {
        evt.preventDefault();
        let url = $('#modal-gift-message').data('url');
        const $this = $(this);
        const $itemCont = $this.parents('.cart-lineitem');
        const from = $itemCont.find('#gmForm').val();
        const gmsg = $itemCont.find('#gmMsg').val();
        const uuid = $this.parents('.product-info').data('uuid');
        const sku = $this.parents('.product-info').data('pid');
        $(this).change(function () {
            giftMessageFormClear($this);
        });
        if (!gmsg.length) {
            // error msg
            $itemCont.find('#gmMsg').addClass('is-invalid');
            $itemCont.find('#gmMsg').next('.invalid-feedback').text('Message field blank');
            return;
        } else if (gmsg.split('\n').length > 4) {
            // exceed error
            $itemCont.find('#gmMsg').addClass('is-invalid');
            $itemCont.find('#gmMsg').next('.invalid-feedback').text('Message has too many lines. The limit is 4');
            return;
        }
        const giftMsgObj = {
            msg: from + '|' + gmsg,
            uuid: uuid,
            sku: sku
        };
        url = appendToUrl(url, giftMsgObj);
        $.ajax({
            url: url,
            method: 'get',
            success: function (data) {
                if (!data.error) {
                    $itemCont.find('.gmFromDisplay').text(`From: ${from}`);
                    $itemCont.find('.gmMsgDisplay').text(gmsg);
                    $itemCont.find('.gmDisplay').removeClass('d-none');
                    $itemCont.find('#modal-gift-message').text('EDIT MESSAGE');
                    $itemCont.find('#modal-gift-message').data('url', data.editUrl);
                } else {
                    // server error
                    showNotification(data, 3000);
                }
                $(`#uuid-${uuid}`).modal('hide');
            },
            error: function () {
                // TO DO
            }
        });
    });

    $('body').on('click', '.stuffing-options .swap-stuffed-state', function () {
        const $this = $(this);
        const uuid = $this.parents('.card.cart-lineitem').data('uuid');
        let url = $this.data('url');
        const $dressedOption = $this.closest('.stuffing-options').siblings('.dressing-options');
        const $stuffedDetail = $this.siblings('.item-stuffed-detail');
        const $stuffedOptionMsg = $this.siblings('.stuffed-option');
        url = appendToUrl(url, { uuid: uuid });

        $.ajax({
            url: url,
            method: 'get',
            success: function (data) {
                let isStuffed = data.isStuffed;
                $stuffedDetail.addClass('d-none');
                if (isStuffed) {
                    $dressedOption.removeClass('d-none').find('.dressed-detail').addClass('d-none');
                    $dressedOption.find('.isDressed').text(data.dressedMsg);
                    $stuffedOptionMsg.removeClass('isUnstuffed').addClass('isStuffed').text(data.stuffedMSg);
                } else {
                    $stuffedOptionMsg.removeClass('isStuffed').addClass('isUnstuffed').text(data.stuffedMSg);
                    $dressedOption.addClass('d-none').find('.dressed-detail').removeClass('d-none');
                }
            }
        });
    });

    $('body').on('click', '.dressing-options .swap-dressing-state', function () {
        const $this = $(this);
        const uuid = $this.parents('.card.cart-lineitem').data('uuid');
        let url = $this.data('url');
        let $dressedDetail = $this.siblings('.dressed-detail');
        let $dressedOptionMsg = $this.siblings('.isDressed');
        url = appendToUrl(url, { uuid: uuid });

        $.ajax({
            url: url,
            method: 'get',
            success: function (data) {
                let isdressed = data.isdressed;
                $dressedDetail.addClass('d-none');
                if (isdressed) {
                    $dressedOptionMsg.text(data.dressedMsg);
                } else {
                    $dressedOptionMsg.text(data.dressedMsg);
                }
            }
        });
    });

    // update packaging prefrence
    $('body').on('click', '.gift-option-wrapper input', function () {
        const $this = $(this);
        const $lineItemCont = $this.parents('.card.cart-lineitem');
        const uuid = $lineItemCont.data('uuid');
        // get checked value
        const giftBox = $this.val();
        let url = $('.cart.cart-page').data('giftboxurl');
        if (!giftBox || !uuid) {
            return;
        }
        const params = {
            uuid: uuid,
            giftBox: giftBox
        };
        url = appendToUrl(url, params);
        $.spinner().start();
        $.ajax({
            url: url,
            method: 'get',
            success: function (data) {
                updateCartTotals(data.basket);
                updateApproachingDiscounts(data.basket.approachingDiscounts);
                validateBasket(data.basket);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('change', '.attr-select', function (evt) {
        evt.preventDefault();
        const uuid = $(this).parents('.card.cart-lineitem').data('uuid');
        let queryParams;
        if (evt.currentTarget.value.split('?').length > 1) {
            queryParams = evt.currentTarget.value.split('?')[1];
        }
        let actionEndpoint = $(evt.currentTarget).find(":selected").data('endpoint');
        actionEndpoint = actionEndpoint + '?' + queryParams;
        actionEndpoint = appendToUrl(actionEndpoint, { uuid: uuid });
        $.spinner().start();

        $.ajax({
            url: actionEndpoint,
            method: 'get',
            success: function (data) {
                updateCartTotals(data.basket);
                validateBasket(data.basket);
                let item = data.basket.items.find(function (item) {
                    return item.UUID === uuid;
                });
                updatePriceTotal(item.id, data.basket.items, uuid);
                // $(`[data-uuid=${uuid}]`).find('.price').replaceWith(item.renderedPrice);
                $(`[data-uuid=${uuid}]`).find('.line-item-quantity select').data('pid', item.id);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $("#checkoutMoviePopup").on('click', function () {
        $(".modal").hide();
    });

    // $(document).ready(function () {
    //     console.log('Test');
    //     if ($('.cart-top-product-slider').length) {
    //         if ($('.qtyRemain').val() == 0) {
    //             console.log('Test');
    //             $(".cart-top-product-slider .add-to-cart-tile").attr('disabled', true);
    //         }
    //     }
    // });

    birthCertificate.init();
    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
